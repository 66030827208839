import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { decryptResponse } from './CryptoUtil';
const Sidebar =()=> {

  const [userlevel,setUserlevel]=useState("USER")
   useEffect(()=>{
          if(localStorage.getItem('userSession')){
            setUserlevel(decryptResponse(JSON.parse(localStorage.getItem("userSession")).data.payload,JSON.parse(localStorage.getItem("userSession")).data.transactionId).userlevel
          )
          }
        },[]
      )
  

    return (

      <div>

        <nav className="sidebar sidebar-offcanvas" id="sidebar">
          <ul className="nav">
            <li className="nav-item ">
            <NavLink to="/" className={({ isActive }) => (isActive ? "nav-link-active" : "nav-link")}>
                <i className="icon-grid menu-icon" />
                <span className="menu-title">Dashboard</span>
              </NavLink>
            </li>

            <li className="nav-item">
            <NavLink to="/blockapp" className={({ isActive }) => (isActive ? "nav-link-active" : "nav-link")} >
                <i className="icon-circle-minus menu-icon" />
                <span className="menu-title">Block App</span>
              </NavLink>
            </li>
            <li className="nav-item">
            <NavLink to="/unblockapp" className={({ isActive }) => (isActive ? "nav-link-active" : "nav-link")}>
                <i className="icon-circle-plus menu-icon" />
                <span className="menu-title">Unblock App</span>
              </NavLink>
            </li>
       

            <li className="nav-item">
            <NavLink to="/lockDevice" className={({ isActive }) => (isActive ? "nav-link-active" : "nav-link")} >
                <i className="icon-lock menu-icon" />
                <span className="menu-title">Lock Device</span>
              </NavLink>
            </li>
            <li className="nav-item">
            <NavLink to="/unlockDevice" className={({ isActive }) => (isActive ? "nav-link-active" : "nav-link")} >
                <i className="icon-unlock menu-icon" />
                <span className="menu-title">Unlock Device</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/notify" className={({ isActive }) => (isActive ? "nav-link-active" : "nav-link")} >
                <i className="icon-bell menu-icon" />
                <span className="menu-title">Notify</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/release" className={({ isActive }) => (isActive ? "nav-link-active" : "nav-link")}>
                <i className="icon-minus menu-icon" />
                <span className="menu-title">Release</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/formattedDevice" className={({ isActive }) => (isActive ? "nav-link-active" : "nav-link")}>
                <i className="icon-minus menu-icon" />
                <span className="menu-title">Formatted Device</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/deviceDetails" className={({ isActive }) => (isActive ? "nav-link-active" : "nav-link")} >
                <i className="icon-align-justify menu-icon" />
                <span className="menu-title">Device Details</span>
              </NavLink>
            </li>

            {userlevel ==="ADMIN" ?
            <li className="nav-item">
              <NavLink to="/userManagement" className={({ isActive }) => (isActive ? "nav-link-active" : "nav-link")} >
                <i className="icon-file menu-icon" />
                <span className="menu-title">User Management</span>
              </NavLink>
            </li>
   :""}
            
            <li className="nav-item">
              <NavLink to="/reports" className={({ isActive }) => (isActive ? "nav-link-active" : "nav-link")} >
                <i className="icon-file menu-icon" />
                <span className="menu-title">Reports</span>
              </NavLink>
            </li>
            { userlevel==="ADMIN" ? <li className="nav-item">
              <NavLink to="/auditReport" className={({ isActive }) => (isActive ? "nav-link-active" : "nav-link")} >
                <i className="icon-file menu-icon" />
                <span className="menu-title">Audit Reports</span>
              </NavLink>
            </li>
            :
            <></>}
            
    

           
            <li className="nav-item">
            <NavLink to="/logout" className={({ isActive }) => (isActive ? "nav-link-active" : "nav-link")}>
                <i className="icon-power menu-icon" />
                <span className="menu-title">Logout</span>
              </NavLink>
            </li>
          </ul>
        </nav>

      </div>




    );
  }
export default Sidebar
