import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { decryptResponse } from './CryptoUtil';
import { BASE_URL, withCredentials } from '../App';

const KeyManagement = ({ userId }) => {
  const [keys, setKeys] = useState([]);
  const [keyName, setKeyName] = useState('');

  useEffect(() => {
    const fetchKeys = async () => {
      //const response = await axios.get(`/view_keys.php?user_id=${userId}`);
      const response = await axios.get(BASE_URL+"/keymanagement"+`/view_keys.php`);
      setKeys(response.data);
    };
    fetchKeys();
  }, [userId]);

  const handleGenerateKey = async () => {
    try {
      const response = await axios.post('/generate_key.php', {
        key_name: keyName,
        user_id: userId,
      }, {
        withCredentials: withCredentials,
        headers: {
        'Content-Type': 'application/json',
        'X-API-Key': decryptResponse(JSON.parse(localStorage.getItem("userSession")).data.payload,JSON.parse(localStorage.getItem("userSession")).data.transactionId).token
        
      }});
      alert(response.data.message);
      setKeys([...keys, response.data]);
    } catch (error) {
      console.error('Error generating key:', error);
    }
  };

  const handleChangeStatus = (keyId, currentStatus) => {
    const newStatus = currentStatus === "active" ? "inactive" : "active";
   

    axios.post(BASE_URL+"keymanagement"+"/update_key_status.php", { keyId, status: newStatus },{
        withCredentials: withCredentials,
        headers: {
        'Content-Type': 'application/json',
        'X-API-Key': decryptResponse(JSON.parse(localStorage.getItem("userSession")).data.payload,JSON.parse(localStorage.getItem("userSession")).data.transactionId).token
        
      }})
      .then(response => {
        // Update the key status in the state
        setKeys(keys.map(key =>
          key.id === keyId ? { ...key, status: newStatus } : key
        ));
      })
      .catch(error => {
        console.error("Error updating key status:", error);
      });
  };

  return (
    <div>
         <h1>Key Management</h1>
     

        <div className="col-md-12 grid-margin transparent">
          <div className="form-group">
            <div className="input-group">
              
            
              <input
              className="form-control form-control-sm pe-5"
        type="text"
        placeholder="Key Name"
        value={keyName}
        onChange={(e) => setKeyName(e.target.value)}
      />
            
              
                <button className="col-3 btn btn-block btn-primary btn-sm" onClick={handleGenerateKey}>Generate Key</button>
               
              
            </div>
          </div>
        </div>
     
      
     
        <div className="col-md-12 grid-margin transparent">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-striped table-borderless">
                <thead>
                    <tr>
                    <th>Key Name</th>
                    <th>Status</th>
                    <th>Created At</th>
                    <th>Actions</th>
                    </tr>
                </thead>
                    
       
                <tbody>
                    {keys.map((key) => (
                    <tr key={key.id}>
                    <td>{key.key_name}</td>
                    <td>{key.status}</td>
                    <td>{key.created_at}</td>
                    <td>
                <button className="btn btn-danger btn-small text-white"onClick={() => handleChangeStatus(key.id, key.status)}>
                  {key.status === "active" ? "Deactivate" : "Activate"} 
                </button>
              </td>
                    </tr>
                ))}
                </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
    </div>
  );
};

export default KeyManagement;
