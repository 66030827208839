import axios from 'axios';
// eslint-disable-next-line no-unused-vars
import React, { useState } from 'react';
import { encryptPayload,decryptResponse } from './CryptoUtil';
import { APP_URL, withCredentials } from '../App';

const DeviceDetail = () => {

  const [items, setItems] = useState([]);
  
 

 

  const handleSearch = (e) => {
    e.preventDefault();

    
  };
  const searchImei=async (e)=>{
    const imei = e.target.value
    if(imei.length===15){
      const data= encryptPayload({ "imei":imei })
     
      try{
      const response = await axios.post(APP_URL+'devicedetails.php',
        data,
        {
          withCredentials: withCredentials,
          headers: {
          'Content-Type': 'application/json',
          'X-API-Key': decryptResponse(JSON.parse(localStorage.getItem("userSession")).data.payload,JSON.parse(localStorage.getItem("userSession")).data.transactionId).token
        
        }})
     
      const decryptText = decryptResponse(response.data.payload,response.data.transactionId)
      setItems(decryptText.items);
      }catch(error){
        alert("Cannot fetch the data now, please try again")
      }
    }else{
      setItems([])
    }

  }


  return (
    <div>
      <div className="row">
      <div className="col-md-12 grid-margin transparent">
          <div className="row">
            <div className="col-md-5 mb-4 transparent">
              <h2 className="font-weight-bold">Device Details</h2>
            </div>
           
          </div>
        </div>
        <div className="col-md-12 grid-margin transparent">
          <div className="form-group">
            <div className="input-group">
              
              <input
                type="text"
                className="form-control form-control-sm pe-5"
                onChange={searchImei}
                placeholder='Search by IMEI'
                aria-label="Search by IMEI"
              />
            
              <button
                className="btn btn-sm btn-primary position-absolute end-0 top-0 bottom-0 rounded-0"
                type="button"
                id="searchButton"
                onClick={handleSearch}
              >
                <i className="icon-search"></i>
              </button>
            </div>
          </div>
        </div>

        {items.length > 0 && (
          <div className="col-md-12 grid-margin transparent">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-striped table-borderless">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>IMEI 1</th>
                        <th>IMEI 2</th>
                        <th>Enrollment Status</th>
                        <th>Registration Status</th>
                        <th>Command</th>
                        <th>D Lock Id</th>
                        <th>App Type</th>
                        <th>Lock Status</th>
                        <th>Created At</th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((item, index) => (
                        <tr key={item.imei1}>
                          <td>{index + 1}</td>
                          <td>{item.imei1}</td>
                          <td>{item.imei2}</td>
                          <td>{item.claimstatus}</td>
                          <td>{item.registrationstatus}</td>
                          <td>{item.command}</td>
                          <td>{item.d_Lock_Id}</td>
                          <td>{item.apptype}</td>
                          <td>{item.devicelockstatus}</td>
                          <td>{item.createdat}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
        {items.length === 0  && (
          <div className="col-md-12 grid-margin transparent">
            <p>No results found</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default DeviceDetail;