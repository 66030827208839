import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { APP_URL, withCredentials } from '../App';
import { decryptResponse } from './CryptoUtil';


  const Home = () => {
    const [total_device,setTotalDevice] = useState(0);
    const [device_today,setDeviceToday] = useState(0);
    const [deenroll,setDeenroll] = useState(0);
    

    useEffect(() => {
    
  
      fetchData();
    }, []); // Empty dependency array ensures it runs once on mount
    const fetchData = async () => {
      try {
       
        // Make Axios call
        const response = await axios.get(APP_URL+'homeData.php',
                {withCredentials: withCredentials,
                  headers: {
                  'Content-Type': 'application/json',
                  'X-API-Key': decryptResponse(JSON.parse(localStorage.getItem("userSession")).data.payload,JSON.parse(localStorage.getItem("userSession")).data.transactionId).token
        
                }})
        
        const decryptText = decryptResponse(response.data.payload,response.data.transactionId);  

      

        setTotalDevice(decryptText.total_device); // Update state with fetched data
        setDeviceToday(decryptText.device_today);
        setDeenroll(decryptText.deenroll);

      } catch (err) {
        //setError(err.message); // Handle error
      } finally {
        //setLoading(false); // Stop loading
      }
    };

    const refreshData=()=>{
      fetchData();

    }

    
  

    return (
     <div>
  <div className="row">
    <div className="col-md-12 grid-margin">
      <div className="row">
        <div className="col-12 col-xl-8 mb-4 mb-xl-0">
         
          
        </div>
        <div className="col-12 col-xl-4">
          <div className="justify-content-end d-flex">
            <div className="dropdown flex-md-grow-1 flex-xl-grow-0">
                
              
             
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="row">
    <div className="col-md-12 grid-margin transparent">
      <div className="row">
        <div className="col-md-6 mb-4 stretch-card transparent">
          <div className="card card-tale">
            <div className="card-body">
           
              <p className="fs-30 mb-2">Download Sample CSV</p>
              <a href='../samplecsv.csv'>Sample Csv</a>
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-4 stretch-card transparent">
          <div className="card card-dark-blue">
            <div className="card-body">
             
              <p className="fs-30 mb-2">Total Devices</p>
              <p>{total_device}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-4 mb-lg-0 stretch-card transparent">
          <div className="card card-light-blue">
            <div className="card-body">
 
              <p className="fs-30 mb-2">De-Enrolled Devices- Required Re-registration</p>
              <p>{deenroll}</p>
            </div>
          </div>
        </div>
        <div className="col-md-6 stretch-card transparent">
          <div className="card card-light-danger">
            <div className="card-body">
        
              <p className="fs-30 mb-2">Today's Registration </p>
              <p>{device_today}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div className="row mb-2" style={{marginRight:"40px",float:"right"}}>
    <button className="col-12 btn btn-block btn-success btn-sm" onClick={refreshData}>Refresh Data</button>
  </div>
 


  </div>

        
  

    );
}


export default Home;
