import axios from 'axios';
// eslint-disable-next-line no-unused-vars
import React, { useState ,useRef} from 'react';
import { encryptPayload,decryptResponse } from './CryptoUtil';
import { APP_URL, withCredentials } from '../App';

const AuditReport = () => {

  const [regitems, setRegItems] = useState([]);
  const [cmditems, setCmdItems] = useState([]);
  const [deenrollitems, setdeenrollItems] = useState([]);
  const [d_Lock_Id,setDLockId]=useState(null)

  const printRef = useRef();

  const handlePrint = () => {

  
    const printContent = printRef.current;
    const printWindow = window.open("", "_blank", "width=800,height=800");
    printWindow.document.write(`<html><head><title>Audit Report </title>
       <style>td{padding:10px}</style></head><body>`);
    printWindow.document.write(printContent.innerHTML);
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
  };

  
 

 

  const handleSearch = (e) => {
    e.preventDefault();

    
  };
  const searchDLockId=async (e)=>{
    const d_Lock_Id = e.target.value

    
      const data= encryptPayload({ "d_Lock_Id":d_Lock_Id })
     
      try{
      const response = await axios.post(APP_URL+'auditReport.php',
        data,
        {
          withCredentials: withCredentials,
          headers: {
          'Content-Type': 'application/json',
          'X-API-Key': decryptResponse(JSON.parse(localStorage.getItem("userSession")).data.payload,JSON.parse(localStorage.getItem("userSession")).data.transactionId).token
        
        }})
     
      const decryptText = decryptResponse(response.data.payload,response.data.transactionId)
      setRegItems(decryptText.registration);
      setCmdItems(decryptText.commands);
      setdeenrollItems(decryptText.deenroll)
      setDLockId(d_Lock_Id)
      }catch(error){
        alert("Cannot fetch the data now, please try again")
      }
    

  }


  return (
    <div >
      <div className="row" >
      <div className="col-md-12 grid-margin transparent">
          <div className="row">
            <div className="col-md-5 mb-4 transparent">
              <h2 className="font-weight-bold">Audit Report By D Lock ID </h2>
            </div>
           
          </div>
        </div>
        <div className="col-md-12 grid-margin transparent">
          <div className="form-group">
            <div className="input-group">
              
              <input
                type="text"
                className="form-control form-control-sm pe-5"
                onChange={searchDLockId}
                placeholder='Enter D Lock Id'
                aria-label="Search by D Lock Id"
              />
            
              <button
                className="btn btn-sm btn-primary position-absolute end-0 top-0 bottom-0 rounded-0"
                type="button"
                id="searchButton"
                onClick={handleSearch}
              >
                <i className="icon-search"></i>
              </button>
            </div>
          </div>
        </div>
        <div ref={printRef}>

        {regitems.length > 0 && (
          <div className="col-md-12 grid-margin transparent">
            <div className="card">
           
               
              <div className="card-body">
              <h5 className="card-title">Registration Details {d_Lock_Id}</h5>
                <div className="table-responsive">
                  <table className="table table-striped " border={1}>
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>IMEI 1</th>
                        <th>IMEI 2</th>
                        <th>Brand</th>
                        <th>Model</th>
                        <th>Command</th>
                        <th>Created At</th>
                      </tr>
                    </thead>
                    <tbody>
                      {regitems.map((item, index) => (
                        <tr key={item.imei1+'reg'}>
                          <td>{index + 1}</td>
                          <td>{item.imei1}</td>
                          <td>{item.imei2}</td>
                          <td>{item.brand}</td>
                          <td>{item.model}</td>
                          <td>{item.command}</td>
                          <td>{item.createdat}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>



        )}

{deenrollitems.length > 0 && (
          <div className="col-md-12 grid-margin transparent">
            <div className="card">
           
               
              <div className="card-body">
              <h5 className="card-title">De-enroll and Re-registration Details {d_Lock_Id}</h5>
                <div className="table-responsive">
                  <table className="table table-striped " border={1}>
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>IMEI 1</th>
                        <th>IMEI 2</th>
                        <th>Action</th>
                        <th>Date</th> 
                       
                      </tr>
                    </thead>
                    <tbody>
                      {deenrollitems.map((item, index) => (
                        <tr key={item.createdat}>
                          <td>{index + 1}</td>
                          <td>{item.imei1}</td>
                          <td>{item.imei2}</td>
                          <td>{item.action}</td>
                          <td>{item.createdat}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>



        )}

{cmditems.length > 0 && (
          <div className="col-md-12 grid-margin transparent">
            <div className="card">
              <div className="card-body">
              <h5 className="card-title">Executed Commands {d_Lock_Id}</h5>
                <div className="table-responsive">
                  <table className="table table-striped " border={1}>
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>IMEI 1</th>
                        
                        <th>Commands</th>
                        <th>Command Date</th>
                        <th>Command Status</th>
                        
                      </tr>
                    </thead>
                    <tbody>
                      {cmditems.map((item, index) => (
                        <tr key={item.createdat}>
                          <td>{index + 1}</td>
                          <td>{item.imei1}</td>
                         
                          <td>{item.command}</td>
                          <td>{item.createdat}</td>
                          <td>{item.status}</td>
                         
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
)}
</div>
        {regitems.length === 0  && (
          <div className="col-md-12 grid-margin transparent">
            <p>No results found</p>
          </div>
        )}
      </div>

      <div className="row mb-2" style={{marginRight:"40px",float:"right"}}>
        <button className="col-12 btn btn-block btn-success btn-sm" onClick={handlePrint}>Print Report</button>
     </div>
    </div>
  );
};

export default AuditReport;