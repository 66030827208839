/* eslint-disable no-unused-vars */

import React, { useState, useEffect,useRef } from 'react';
import axios from 'axios';
import { APP_URL,BATCH_SIZE, withCredentials } from '../App';
import {encryptPayload,decryptResponse} from './CryptoUtil';
import { useNavigate } from 'react-router-dom';



const FormattedDevice = () => {
  const [error, setError] = useState('');
  const navigate = useNavigate() 
  const [singleImei, setSingleImei] = useState('');



  const handleSingleImei=(event)=>{
    setSingleImei(event.target.value)  
  }

  const handleFormattedDevices=async (event)=>{
    event.preventDefault();   
    const action ="formatteddevice"  
   
    try {
      const response = await axios.post(APP_URL+'formattedDevice.php',
      encryptPayload( { "imei":singleImei,
        "action": action }),
        {
          withCredentials: withCredentials,
          headers: {
          'Content-Type': 'application/json',
          'X-API-Key': decryptResponse(JSON.parse(localStorage.getItem("userSession")).data.payload,JSON.parse(localStorage.getItem("userSession")).data.transactionId).token
        
        }}
      )
      const decryptText = decryptResponse(response.data.payload,response.data.transactionId);
      if (decryptText.status === 'success') {           
         alert("Formatted Device de-enrolled successfully. Re-install the app immediately");
         //setSingleImei('')
       
      } else if (decryptText.status !== 'success') {
        
        if(decryptText.message==="Authorization Failed.Invalid" || decryptText.message==="Authorization Failed"){
          localStorage.removeItem('userSession');
          navigate("/")
        }else{          
      
       
          alert(decryptText.message);
        }
        
      }
      } catch (error) {
        /* continue regardless of error */
        
      }
    };

 




  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6 grid-margin transparent">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">De-enroll formatted device</h4>
              <form className="forms-sample" onSubmit={handleFormattedDevices}>
                <div className="form-group">
                 
                  <div className="col-sm-12">
                  <p>IMEI</p>
                  <input type="number" min="100000000000000" max="999999999999999" placeholder="Enter IMEI of device" className="form-control   input-sm "  required onChange= {handleSingleImei} />
               
                    <p className='p-2'>Please provide a 15 digit IMEI number</p>
                  </div>
                </div>
                <div className="submit-buttons">
                  <button type="submit" className="btn btn-success me-2 btn-sm text-white" >
                    Submit
                  </button>
                 
                </div>
              </form>
            </div>
          </div>
        </div>
       
 
      </div>
    </div>
  );
};

export default FormattedDevice;
