import React from 'react';

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/login.js';

import Home from './components/home.js';
import Device_detail from './components/device_detail.js';
import Notify from './components/notify.js';
import Release from './components/release.js';
import Template from './components/template';


import Lock_device from './components/lock_device.js';
import Unlock_device from './components/unlock_device.js';
import History from './components/history.js';
import Logout from './components/logout.js';
import BlockApp from './components/blockapp.js';
import UnblockApp from './components/unblockapp.js';



import User_Management from './components/User_Management.js';
import ResetPassword from './components/ResetPassword.js';
import ForgotPassword from './components/ForgotPassword.js';
import KeyManagement from './components/KeyManagement.js';
import FormattedDevice from './components/FormattedDevice.js';
import { decryptResponse } from './components/CryptoUtil.js';
import AuditReport from './components/AuditReport.js';

export const BATCH_SIZE =30
export const withCredentials = true;
export const BASE_URL ="https://www.screenbros.co";
export const APP_URL =BASE_URL+"/webapi/";

const PrivateRoute = ({ element: Component }) => {
  let isAuthenticated=false;
  if(localStorage.getItem('userSession')){
    isAuthenticated = decryptResponse(JSON.parse(localStorage.getItem("userSession")).data.payload,JSON.parse(localStorage.getItem("userSession")).data.transactionId).loggedin === 'loginValidated';
  }  
  return isAuthenticated ? <Component /> :<Navigate to="/login" />;
};

const AdminManagementRoute = ({ element: Component }) => {
  let isAdmin=false;
  if(localStorage.getItem('userSession')){
    isAdmin= decryptResponse(JSON.parse(localStorage.getItem("userSession")).data.payload,JSON.parse(localStorage.getItem("userSession")).data.transactionId).userlevel === 'ADMIN';
  }
  
  return isAdmin ? <Component /> : <Navigate to="/login" />;
};

function App() {


  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgotPassword" element={<ForgotPassword/>} />
        <Route path="/resetPassword/:token" element={<ResetPassword/>} />
          <Route path="/" element={<Template />}>
          <Route index element={<PrivateRoute element={Home} />} />
          <Route path="lockDevice" element={<PrivateRoute element={Lock_device} />} />
          <Route path="blockApp" element={<PrivateRoute element={BlockApp} />} />
          <Route path="unblockapp" element={<PrivateRoute element={UnblockApp} />} />
          <Route path="unlockDevice" element={<PrivateRoute element={Unlock_device} />} />
          <Route path="release" element={<PrivateRoute element={Release} />} />
          <Route path="deviceDetails" element={<PrivateRoute element={Device_detail} />} />
          <Route path="formattedDevice" element={<PrivateRoute element={FormattedDevice} />} />
          <Route path="notify" element={<PrivateRoute element={Notify} />} />
          <Route path="userManagement" element={<AdminManagementRoute element={User_Management} />} />
          {/* <Route path="report" element={<PrivateRoute element={Report} />} /> */}
          <Route path="reports" element={<PrivateRoute element={History} />} />
          <Route path="auditReport" element={<AdminManagementRoute element={AuditReport} />} />
          <Route path="keyManagement" element={<KeyManagement/>} />         
         
          <Route path="logout" element={<PrivateRoute element={Logout} />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
