/* eslint-disable no-unused-vars */
import React, { useState, useEffect,useRef } from 'react';
import axios from 'axios';
import { APP_URL, BATCH_SIZE, withCredentials } from '../App';
import {decryptResponse, encryptPayload} from './CryptoUtil';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';


const Notify = () => {
  const navigate = useNavigate()
  const [error, setError] = useState('');
  const [preview, setPreview] = useState('');

  const [fileName, setFileName] = useState('');  
  const [file, setFile] = useState(null);
  const [fileSize, setFileSize] = useState(0);
  const fileInputRef = useRef(null);

  const [csvContent, setCsvContent] = useState('');    
  const [jsonContent, setJsonContent] = useState(null);

  const [incorrectData, setIncorrectData] = useState([]);
  const [correctData, setCorrectData] = useState([]);
  const [correctImeiData, setCorrectImeiData] = useState([]);

  const [downloadUrl, setDownloadUrl] = useState(null);

  const [showDownloadButton, setShowDownloadButton] = useState(false);
 

  const [singleImei, setSingleImei] = useState('');

  const [readingTime, setReadingTime] = useState(0);
  const [processTime,setProcessTime]= useState(0)

  const [pleasWait ,setPleaseWait] = useState(false)
  const [processBatchWait ,setProcessBatchWait] = useState(false)

  

  //pending bulk notify
  const [pendingBulkNotify, setPendingBulkNotify] = useState(false);
  const [lastimei, setLastImei] = useState();
  const [batchfilename, setBatchFileName] = useState();

  //batch Complete
  const [batchComplete, setBatchComplete] = useState(false);


  //autoscrolling
  const tableEndRef = useRef(null);

  //show hide batch upload
  const [newBatch,setNewBatch] = useState(true);

 const [selectedOption, setSelectedOption] = useState(0);
 const [message,setMessage]=useState('');




  useEffect(()=>{
   
    const notifyBatchStatus = JSON.parse(localStorage.getItem('notifyBatchStatus'))
    
   
    try{
     
      if(notifyBatchStatus.isComplete==="incomplete"){
        setNewBatch(false)
        const pendingBulkNotify=true
        setPendingBulkNotify(pendingBulkNotify)        
       
        setLastImei(notifyBatchStatus.lastimei)
        setBatchFileName(notifyBatchStatus.batchfilename)
        updateStatusOnServer(notifyBatchStatus)

      }else{
        setNewBatch(true)
        const pendingBulkNotify=false
        setPendingBulkNotify(pendingBulkNotify)
       
        setLastImei('')
        setBatchFileName('')
        updateStatusOnServer(notifyBatchStatus)

      }
  }catch(error){
     /* continue regardless of error */
    

  }

  },[])

  useEffect(()=>{
  
    
    if(batchComplete){
      
      const notifyBatchStatus = JSON.parse(localStorage.getItem('notifyBatchStatus'))
      updateStatusOnServer(notifyBatchStatus)
      setPendingBulkNotify(false)
      localStorage.removeItem("notifyBatchStatus")
      localStorage.removeItem("correctedDataNotify")
    }

  },[batchComplete,newBatch])

  const updateStatusOnServer=async(notifyBatchStatus)=>{
   
    try{
      let isComplete= notifyBatchStatus.isComplete;    
      
     
      const response = await axios.post(APP_URL+'updateStatusOnServer.php',
        encryptPayload({'lastimei':notifyBatchStatus.lastimei,
          'fileName':notifyBatchStatus.batchfilename,
          'isComplete':isComplete,
          'statusOf':'notifyfiles'

        }),
        {withCredentials: withCredentials,
          headers: {
          'Content-Type': 'application/json',
          'X-API-Key': decryptResponse(JSON.parse(localStorage.getItem("userSession")).data.payload,JSON.parse(localStorage.getItem("userSession")).data.transactionId).token
        
        }})
     


    }catch(error){
       /* continue regardless of error */
      

    }
  }


 
  const sendRequestToServer = async (number) => {
   
    try {
      const response = await axios.post(APP_URL+'getimeidetails.php',
       encryptPayload( { "imei":number,
         "action":"notify",
         "filename":fileName
         }),
         {
          withCredentials: withCredentials,
          headers: {
           'Content-Type': 'application/json',
           'X-API-Key': decryptResponse(JSON.parse(localStorage.getItem("userSession")).data.payload,JSON.parse(localStorage.getItem("userSession")).data.transactionId).token
        
         }}
       )
      return response.data;
    } catch (error) {
     
      return { "status": "failed","message":"not found"};
    }
  };

  const processBatch = async (numbersBatch) => {
    const results = [];
    for (const number of numbersBatch) {
     
        const result = await sendRequestToServer(number);
       
       
        results.push({'imei':number,'validate':result.message});
     
        if(correctData[correctData.length-1]===number){
         
          const notifyBatchStatus ={
            'lastimei':number,
            'batchfilename':fileName,
            'isComplete':'complete'
          }        
          localStorage.setItem("notifyBatchStatus",JSON.stringify(notifyBatchStatus)) 
         
          const batchComplete=true        
          setBatchComplete(batchComplete)
          const newBatch=true          
          setNewBatch(newBatch)
         

        }else{
         
           
           const notifyBatchStatus ={
            'lastimei':number,
            'batchfilename':fileName,
            'isComplete':'incomplete'
          }
       
         

          localStorage.setItem("notifyBatchStatus",JSON.stringify(notifyBatchStatus))
         
         

        }
       
       
       
     
    }
    return results;
  };
 
  const processAllNumbers = async (numbers) => {

    const startTimeProcess = performance.now();
         
    const batchSize = BATCH_SIZE;
   
    setProcessBatchWait(true)
 
    for (let i = 0; i < numbers.length; i += batchSize) {
     
       
      const batch = numbers.slice(i, i + batchSize);
      const batchResults = await processBatch(batch);
      setCorrectImeiData(prevData => [...prevData, ...batchResults]); // Append batch results to table
      await new Promise(resolve => setTimeout(resolve, 0)); // Yield to the event loop to prevent freezing
     

     
    }
    const endTimeProcess = performance.now();
   

    const timeElapsedProcess = (endTimeProcess - startTimeProcess).toFixed(2);
    setProcessTime(timeElapsedProcess);
    setProcessBatchWait(false);
   
  };

  useEffect(()=>{
   if (tableEndRef.current) {
      tableEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }

  },[correctImeiData]);
 
  useEffect(() => {
   
    setPleaseWait(false)
    processAllNumbers(correctData);
  }, [correctData]);
 
 

 



 const processUploadedFile= async (selectedFile,correctData)=>{
 
     
     
      const file = selectedFile
      setFile(file)
     
      if (file) {        
        const fileType = file.type;
        if (fileType === 'text/csv') {    
         

        const formData = new FormData();
        formData.append('file', file);
        formData.append('accountid',decryptResponse(JSON.parse(localStorage.getItem("userSession")).data.payload,JSON.parse(localStorage.getItem("userSession")).data.transactionId).accountid)
                
       
       
        try {
         
          const response = await fetch(APP_URL+'bulkNotifyCopy.php', {
            method: 'POST',
            body: formData,
            headers: {
              'X-API-Key': decryptResponse(JSON.parse(localStorage.getItem("userSession")).data.payload,JSON.parse(localStorage.getItem("userSession")).data.transactionId).token
        
              }
          });
          const result = await response.json();
         
          const decryptText = decryptResponse(result.payload,result.transactionId)
         
     
          if (decryptText.status==="success") {
           
            setError('');
            setCorrectData(correctData);
            localStorage.setItem("correctedDataNotify",JSON.stringify(correctData))
            updateNotificationCommandOnServer(localStorage.getItem("correctedDataNotify"))
           
           // processUploadedFile(selectedFile)        
         
          } else {
            alert(decryptText.message);
            setError(decryptText.message);
          }
       }catch (error) {
       
         
        setError('An error occurred while uploading the file.');
      }

       
      } else {
        setFile(null);
        setError('Only CSV files are allowed.');
       
      }
    }
 }

  const handleFileUpload =  (event) => {
   
    event.preventDefault()
    // resetting states
    setCorrectData([]);
    setCorrectImeiData([]);
    setIncorrectData([])
    setFile(null);
    setNewBatch(true);
    setBatchComplete(false);
    setCsvContent([]);
    setError([]);
    setFileName('');
    setFileSize('');
    setBatchFileName('');
    setProcessTime(0);
    setPleaseWait();
    setPendingBulkNotify(false);
    setProcessBatchWait(false);

    
    const selectedFile = event.target.files[0];
    const file = selectedFile
    setFile(file)
   
       
    setFileName(selectedFile.name);
   
    setFileSize(selectedFile.size);
    const reader = new FileReader();
    const startTime = performance.now();
    reader.onload = (e) => {
    const content = e.target.result;
    const validation = validateCsvContent(content);

    if (validation.isValid) {
      setCsvContent(content);
      setFile(selectedFile);
     
      setError('');
      parseCsv(content);
     
      processUploadedFile(file,validation.correctData)

    } else {
      setError(validation.error);
      setIncorrectData(validation.incorrectData);
      setJsonContent(null);
      setShowDownloadButton(true);

    }
    const endTime = performance.now();
    const timeElapsed = (endTime - startTime).toFixed(2);
    setReadingTime(timeElapsed);
 
    };
    reader.readAsText(selectedFile);

   
  };

  const validateCsvContent = (content) => {
    const lines = content.trim().split('\n').filter(line => line.trim());
   
    const incorrectData = [];
    const correctData =[];
    const firstCell = lines[0].split(',');
    if(firstCell[0].trim().toLowerCase()==="sno" && firstCell[1].trim().toLowerCase()==="imei1") {
    
    for (let i = 1; i < lines.length; i++) {
      const cells = lines[i].split(',').map(cell => cell.trim());
   
      if(cells.length===2){
        for (let j = 1; j < cells.length; j++) {

          
      
          if (!/^\d{15}$/.test(cells[j].trim())) {
            const errorLine = i ;
           // const errorCellIndex = j + 1;
            const errorCellData = cells[j].trim();
            incorrectData.push({
              line: errorLine,
              error: "Invalid Length found in imei, only 15 digits allowed",
              data: errorCellData
            });
          }else{
           
            correctData.push(cells[j].trim())
           
          }  
      }

      }else{
     
        const errorLine = i ;
         // const errorCellIndex = j + 1;
        const errorCellData = cells.join('-');
          incorrectData.push({
            line: errorLine,
            error: "More than two columns found. please check your file",
            data: errorCellData
          });

      
      }


    }
  }else{
    incorrectData.push({
      line: 1,
      error: "Invalid Headers",
      data: "headers not matched"
    });

  }
    if (incorrectData.length > 0 ) {
      handleDownloadIncorrectData(incorrectData);
      return {
        isValid: false,
        error: `CSV contains incorrect format data.`,
        incorrectData: incorrectData
      };
    }else if(correctData.length>0 && incorrectData.length===0){
     
      return {
        isValid : true,
        correctData : correctData
      }
         

    }
    setPleaseWait(true)
    return { isValid: true };
  };

  //update notification command on server
  const updateNotificationCommandOnServer=async (imeilist)=>{
    const jsonArray = JSON.parse(imeilist);

  // Step 2: Convert array to a comma-separated string
  const imeilist1 = jsonArray.join(',');
 
    try{
      const response = await axios.put(APP_URL+"updateCommandOnServer.php",
      encryptPayload(  {'imeilist':imeilist1,
          'action':'notify'
        }),
        {withCredentials: withCredentials,
          headers: {
          'Content-Type': 'application/json',
          'X-API-Key': decryptResponse(JSON.parse(localStorage.getItem("userSession")).data.payload,JSON.parse(localStorage.getItem("userSession")).data.transactionId).token
        
        }}
      )
   
     
     
    }catch(error){
       /* continue regardless of error */

    }
  }

  const parseCsv = (content) => {
    const lines = content.split('\n').filter(line => line.trim());
    const data = lines.map(line => line.split(',').map(cell => cell.trim()));
    setJsonContent(JSON.stringify(data, null, 2));
  };

  const handleUploadClick = () => {
    resetComponent()
    fileInputRef.current.click();
  };

    const handleDownloadIncorrectData = (incorrectData) => {
    const csvContent = generateCsvContent(incorrectData);
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    setDownloadUrl(url);
  };

  const generateCsvContent = (incorrectData) => {
   
    const lines = incorrectData.map(error => `${error.line},${error.data},${error.error}`);
    return 'Sno,Incorrect Data,Error Message\n' + lines.join('\n');
  };

 
  const resetComponent = () => {
    setFileName('');
    setError('');
    setFile(null);
    setFileSize(0);
    setCsvContent('');
    setJsonContent(null);
    setIncorrectData([]);
    setDownloadUrl(null);
    setShowDownloadButton(false);   
    setReadingTime(0);
    setIncorrectData([])
    setCorrectData([])
    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Reset the file input
    }
  };
  const handleSingleImei=(event)=>{
    //alert(event.target.value)
   
    setSingleImei(event.target.value)  
  }

  
  
 
  

  const handleNotify=async (event)=>{
    event.preventDefault();
    if (!selectedOption) {
      alert("please select a template")
      return;
    }
   
    let message1=""

    const today = moment();
   

    
    
    
    
   
    switch(parseInt(selectedOption)){

    
             
      case 1:      
        
        message1="Dear Customer, your upcoming EMI date is on "+today.add(7, 'days').format('DD-MM-YYYY') +" Keep sufficient balance in your bank account to avoid penalty. In case of non-payment, your mobile can be locked as per the agreed terms."
        
        setMessage(message1)
        
        break;
      case 2:
        message1="Dear Customer, your upcoming EMI date is on "+ today.add(3, 'days').format('DD-MM-YYYY')+" Keep sufficient balance in your bank account to avoid penalty. In case of non-payment, your mobile can be locked as per the agreed terms."
        setMessage(message1)
       
        break;
      case 3:
        message1="Dear Customer, your upcoming EMI date is on "+today.add(1, 'days').format('DD-MM-YYYY')+" Keep sufficient balance in your bank account to avoid penalty. In case of non-payment, your mobile can be locked as per the agreed terms."
        setMessage(message1)
       
        break;
      case 4:
        message1="If payment is not made today i.e "+today.add(0, 'days').format('DD-MM-YYYY')+", your mobile will be locked as per the agreed terms. Please ignore if the payment is already done."
        setMessage(message1)
       
        break;
      case 5:
        message1="Dear customer, your device will be locked in 5 days as you have not paid the HDBFS EMI on time. In order to avoid device getting locked, please clear your dues immediately."
        setMessage(message1)
       
        break;
      case 6:
        message1="Dear customer, your device will be locked in 3 days as you have not paid the HDBFS EMI on time. In order to avoid device getting locked, please clear your dues immediately."
        setMessage(message1)
       
        break;
      case 7:
        message1="Dear customer, your device will be locked tomorrow as you have not paid the EMI on time. In order to avoid device getting locked, please clear your dues immediately."
        setMessage(message1)
       
        break;
      default:
        setPreview('')
    


    }
   
    const action ="notify" 

    if (window.confirm('Notification Preview \n\n'+message1) === true) {
      try {
        console.log(message1)
        const response = await axios.post(APP_URL+'locknotification.php',
        encryptPayload( { "imei":singleImei,
          "action": action ,
          "message":message1}),
          {
            withCredentials: withCredentials,
            headers: {
            'Content-Type': 'application/json',
            'X-API-Key': decryptResponse(JSON.parse(localStorage.getItem("userSession")).data.payload,JSON.parse(localStorage.getItem("userSession")).data.transactionId).token
          
          }}
        )
  
        const decryptText = decryptResponse(response.data.payload,response.data.transactionId)
       
          if (decryptText.status === 'success') {
               
             alert("Reminder Notification sent successfully.");
             //setSingleImei('')
           
          } else if (decryptText.status !== 'success') {
  
            if(decryptText.message==="Authorization Failed.Invalid" || decryptText.message==="Authorization Failed"){
              localStorage.removeItem('userSession');
              navigate("/")
            }else{          
          
           
              alert(decryptText.message);
            }
            
          }
        } catch (error) {
           /* continue regardless of error */
         
        }
     
    } else {

      setSelectedOption(0)
      setPreview('')
      setSingleImei('')
      
    }
   
  
   
  

    };

 
  const hidePendingBatch=()=>{
    setPendingBulkNotify(false)
  }



  const handleProcessBatch=()=>{
    setNewBatch(false)
   
    const correctData = JSON.parse(localStorage.getItem('correctedDataNotify'));
    //setCorrectImeiData(prevData => [...prevData])
 
    const lastimei = JSON.parse(localStorage.getItem('notifyBatchStatus')).lastimei
    const fileName = JSON.parse(localStorage.getItem('notifyBatchStatus')).batchfilename
    setBatchFileName(fileName);
    setFileName(fileName)

   
    const index = correctData.indexOf(lastimei);

    if (index !== -1) {
     
      const unFinishedCorrectedData = correctData.slice(index + 1);
      setCorrectData(unFinishedCorrectedData)
      //processAllNumbers(unFinishedCorrectedData)
    } else {
      alert("This batch cannot be processed, please start a new batch or cancel this batch")
    }
   

  }
  const handleCancelBatch=()=>{
    setNewBatch(true)
   
   
   // processAllNumbers()
  }
  const handleNewBatch=()=>{
    setNewBatch(true)
    hidePendingBatch()
   
  }
  const handleTemplate=(e)=>{
   
   
    setSelectedOption(e.target.value)
   
    

  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6 grid-margin transparent">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Notify: Single</h4>
              <form className="forms-sample" onSubmit={handleNotify}>
                <div className="form-group">
                  
                  <div className="col-sm-12">
                 <p>IMEI</p>
                  <input type="number" min="100000000000000" max="999999999999999" placeholder="Enter IMEI of device" className="form-control   input-sm "  required onInput= {handleSingleImei} />
                  
                    <p className='p-2'>Please provide a 15 digit IMEI number</p>
                  </div>
                </div>

                <div className="form-group">
                  <p>Choose A Notification Template</p>
                  <div className="col-sm-12">
                    <select className='form-control input-sm ' value={selectedOption} onChange={handleTemplate} required>
                    <option value="0">----Choose a template----</option>
                      <option value="1">Seven days before due data</option>
                      <option value="2">Three days before due data</option>
                      <option value="3">One days before due data</option>
                      <option value="4">Due day is today</option>
                      <option value="5">Five days before locking</option>
                      <option value="6">Three days before locking</option>
                      <option value="7">One days before locking</option>
                    </select>
                  
                    <p className='p-2'>Please Select a notification template</p>
                  </div>
                </div>
                


                <div className="submit-buttons">
                  <button type="submit" className="btn btn-success me-2 btn-sm text-white" >
                    Submit
                  </button>
                 
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-md-6 grid-margin transparent">

         { pendingBulkNotify && (
          <div className='card' style={{marginBottom:'30px'}}>
            <div className="card-body">          
              <h4 className="card-title">Incomplete Batch Available</h4>
              <hr></hr>
              <p>Filename : {batchfilename}</p>
              <p>Last Processed Imei :{lastimei}</p>
              <hr></hr>
              <p className='text-center text-primary' >Process / Cancel / Start New Batch </p>
              <p className='text-center'>
                <button className="btn btn-success btn-sm text-white marginLeftRight" onClick={handleProcessBatch}>Process</button> <span></span>
                <button className="btn btn-danger btn-sm text-white marginLeftRight" onClick={handleCancelBatch}>Cancel</button> <span></span>
                <button className="btn btn-primary btn-sm text-white marginLeftRight" onClick={handleNewBatch}>New Batch</button>
             
              </p>
            </div>

          </div>

          )}

          {newBatch &&(<div className="card">
            <div className="card-body">
              <h4 className="card-title">IMEI Notify: Batch Upload</h4>
              <form className="forms-sample" >
                <div className="form-group">
                 
                  <input
                    type="file"
                    className="file-upload-default"
                    id="fileInput"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileUpload}
                  />
              
                  <div className="input-group">                    
                    <input
                      type="text"
                      className="form-control input-sm file-upload-info"
                      disabled
                      placeholder="Upload CSV"
                      value={fileName}
                    />
                    <span className="input-group-append">
                      <button
                        className="file-upload-browse btn btn-primary btn-sm"
                        type="button"
                        onClick={handleUploadClick}
                      >
                        Select File
                      </button>
                    </span>
                  </div>
                </div>
                {error && <p className="text-danger">{error}</p>}
                {file && (
                  <p>
                    File Details : {file.name} ({(fileSize / 1024).toFixed(2)} KB)
                    {readingTime > 0 && `<br/> - Reading time: ${readingTime} ms`}
                  </p>
                )}
                {incorrectData.length > 0 && (
                  <div className="invalid-imei-container">
                    <div className="row d d-flex ">
                      <h4 className="col-md-4 mx-3">Invalid IMEI:</h4>
                      {downloadUrl && (
                        <a
                          className=""
                          href={downloadUrl}
                          download="incorrect_data.csv"
                        >
                          Click here to download
                        </a>
                      )}
                    </div>
                    <p>
                      File size: {(fileSize / 1024).toFixed(2)} KB
                      <span className="mx-2">|</span>
                      Reading time: {readingTime} ms
                    </p>

                   
               
                  </div>
                )}
              
             
              </form>
            </div>
          </div>)
       }


        </div>
        <div className="row">
         <div className="col-md-12 grid-margin transparent">
         {pleasWait && (
          <div className="loading-message">
              <div className="spinner"></div>
            <p>Please wait while we evaluate this batch. You are requested not to refresh/reload/or navigate </p>
           
           
          </div>
        )}


      {processBatchWait && (
          <div className="loading-message">
           
            <div className="spinner"></div>
            <p >Please Wait while we are executing this batch.You are requested not to refresh/reload/or navigate .</p>
           
         
          </div>
        )}

       
        {batchComplete &&(
          <div>
            <br></br>
            <p>Process Time : {processTime} ms</p>
          </div>
        )}
       
         
       
         <p style={{backgroundColor: 'white',padding:5}}> Batch Summary - Total {correctData.length + incorrectData.length} ||   <span style={{color:'green'}}>Valid Imei :{correctData.length} </span>  ||  <span style={{color:'red'}}>Invalid Imei :{incorrectData.length} </span> || <span style={{color:'green'}}> Found {correctImeiData.filter((item)=>{return item.validate==="found"}).length}</span> || <span style={{color:'red'}}> Not Found {correctImeiData.filter((item)=>{return item.validate==="not found"}).length}</span></p>
         <div style={{height:'350px',overflow:'scroll'}}>
         <table className="table table-bordered mt-3">
                      <thead>
                        <tr>
                        <th>Sno</th>
                          <th>IMEI</th>
                          <th>Status</th>
                         
                        </tr>
                      </thead>
                      <tbody>
                        {correctImeiData.map((item, index) => (
                          <tr key={item.imei} >
                             <td>{index+1}</td>
                            <td>{item.imei}</td>
                            <td>{item.validate}</td>
                           
                           
                          </tr>
                        ))}
                        <tr ref={tableEndRef}></tr>
                      </tbody>
                    </table>
          </div>
         </div>
        </div>
      </div>
    </div>
  );
};

export default Notify;
